import styled from 'styled-components';

const Ul = styled.ul.attrs((props) => ({
  role: props.role || 'list',
  'aria-label': props.ariaLabel,
}))`
  margin: 0 -0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  li {
    min-width: 13.8125rem;
    font-size: 1rem;
    list-style-type: none;
    text-align: center;
    flex-basis: 29%;
    margin: 0.5rem;
    min-height: 7rem;
    background-color: ${({ theme }) => theme.directors.background};
    flex-grow: 1;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    height: 7.8rem;
    justify-content: center;
    h4 {
      margin-bottom: 1rem;
    }
  }
`;

export default Ul;
