import React from 'react';
import { Link } from 'gatsby';
import ReactHtmlParser from 'html-react-parser';
import styled from 'styled-components';
import Accordion from 'ground-web/components/v1/Accordion';
import SEO from '../components/seo';
import PageTitle from '../components/page-title';
import Article from '../components/article';
import Ul from '../components/directorsCards';
import { decodeHtml } from '../helpers';
import HighlightedWidget, { withCouncilNews } from '../components/news-widget';
import Button from '../components/button';

const PageAccordion = styled.div`
  background: ${({ theme }) => theme.colors.white};

  h3 {
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.text.getSize('h4')};
    font-weight: 700;
    line-height: 1.2em;
    word-spacing: inherit;
    letter-spacing: inherit;
  }

  .accordion-content {
    padding: 1rem;
    word-spacing: normal;
    letter-spacing: normal;
    line-height: 1.5em;
    max-height: 50vh;
    overflow: scroll;
  }
`;

const Container = styled.div`
  .title-with-margin-top {
    margin-top: 3.5rem;
  }
`;

export default function ConselhoDeliberativo(props) {
  const {
    pageContext: { page },
  } = props;
  const {
    mesaDiretora: directors,
    conselheiros,
    comissoes,
  } = page.camposConselho;

  const president = directors.filter(
    (director) =>
      director.cargo.toLowerCase() === 'presidente' ||
      director.cargo.toLowerCase() === 'vice-presidente',
  );
  const secretary = directors.filter(
    (director) =>
      director.cargo.toLowerCase() !== 'presidente' &&
      director.cargo.toLowerCase() !== 'vice-presidente',
  );

  return (
    <Container>
      <SEO title={decodeHtml(page.title)} />
      <Article>
        <PageTitle>{decodeHtml(page.title)}</PageTitle>
        <h2>Mesa Diretora</h2>
        <Ul role="list" ariaLabel="presidencia">
          {president.map((director, index) => (
            <li key={index}>
              <h4>{decodeHtml(director.nome)}</h4>
              <span>{decodeHtml(director.cargo)}</span>
            </li>
          ))}
        </Ul>
        <Ul role="list" ariaLabel="secretaria">
          {secretary.map((director, index) => (
            <li key={index}>
              <h4>{decodeHtml(director.nome)}</h4>
              <span>{decodeHtml(director.cargo)}</span>
            </li>
          ))}
        </Ul>
        <PageAccordion>
          <Accordion title="Conselheiros">
            <div className="accordion-content">
              {ReactHtmlParser(conselheiros)}
            </div>
          </Accordion>
        </PageAccordion>
        <h2 className="title-with-margin-top">Comissões</h2>
        {comissoes.map((item, index) => (
          <PageAccordion key={index}>
            <Accordion title={decodeHtml(item.nome)}>
              <div className="accordion-content">
                {ReactHtmlParser(item.nominata)}
              </div>
            </Accordion>
          </PageAccordion>
        ))}
        {/* <h2 className="title-with-margin-top">
          Reuniões do Conselho Deliberativo
        </h2>
        <Link to="/noticias/conselheiros-lista-de-presenca-e-atas-das-sessoes">
          <Button>
            Atas e Listas de Frequência (Exclusivo para Associados)
          </Button>
        </Link> */}
        <h2 className="title-with-margin-top">Notícias</h2>
        <HighlightedWidget HOC={withCouncilNews} />
      </Article>
    </Container>
  );
}
